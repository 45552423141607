@font-face {
  font-family: 'CelebesSemiBold';
  src: url('./fonts/Celebes-SemiBold.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'CelebesSemiBold', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  font-family: 'CelebesSemiBold', sans-serif;
}

@font-face {
  font-family: 'CelebesThin';
  src: url('./fonts/Celebes-Thin.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
}
