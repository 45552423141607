.slick-prev:before,
.slick-next:before {
  color: black;
}

.slick-slide img {
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  transition: transform 0.4s ease;
}

.slick-slide:hover img {
  transform: scale(1.05);
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

.slick-slide img {
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  transition: transform 0.4s ease;
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.slick-slide:hover img {
  transform: scale(1.05);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.enlarged-image {
  max-width: 90%;
  max-height: 90%;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1001;
}

