@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: 'CelebesSemiBold';
  src: url('./fonts/Celebes-SemiBold.ttf') format('truetype');
}

body {
  font-family: 'CelebesSemiBold', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: 'CelebesSemiBold', sans-serif;
} */

/* .underglow-hover {
  transition: box-shadow 0.2s ease-in-out;
}

.underglow-hover:hover {
  box-shadow: 0 4px 7px rgba(255, 255, 255, 0.75);
  transition: box-shadow 0.5s ease-in-out;
}

.underglow-hover:not(:hover) {
  transition: box-shadow 0.5s ease-in-out;
  box-shadow: none;
} */

.animate-word {
  display: inline-block;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s forwards;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-word:nth-child(1) {
  animation-delay: 0.5s;
}

.animate-word:nth-child(2) {
  animation-delay: 1s;
}

.animate-word:nth-child(3) {
  animation-delay: 1.5s;
}
