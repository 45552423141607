.slide-in-border {
  position: relative;
  transition: transform 0.3s ease-out;
}

.slide-in-border::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px; /* Adjust the height as needed */
  background-color: #2b2b2b; /* Adjust the color as needed */
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease-out;
}

.slide-in-border:hover::after {
  transform: scaleX(1);
}

.slide-in-border:hover {
  transform: scale(1.1);
}
.custom-footer {
  padding-top: 30px !important;
  padding-bottom: 0px !important;
}

.custom-footer .rc-footer-container {
  padding: 0 10px !important; /* Adjust side paddings if needed */
}
.custom-footer .rc-footer-column {
  margin-bottom: 10px !important; /* Adjust side paddings if needed */
}

.custom-footer .rc-footer-bottom-container {
  font-size: 12px;
  padding: 1px;
}
